import 'airbnb-browser-shims';
// jQuery
import 'jquery';
// PopperJS
import 'popper.js';
// Bootstrap 4
import 'bootstrap';

// Material Design Bootstrap
import '../../vendors/mdb/js/mdb';
// import 'mdbootstrap/js/mdb';

import '../../vendors/mdb/js/addons/datatables';
import '../../vendors/mdb/js/addons/mdb-file-upload';

import '../../config/pickadate-it_IT';
/**
 * TinyMCE initialization script
 */
function checkWysiwygEditor() {
  window.tinyMCE.init({
    selector: '.editor',
    theme: 'modern',
    skin: 'lightgray',
    content_css: '/css/main.css',
    min_height: 400,
    language: 'it',
    plugins: [
      'advlist autolink link image imagetools lists charmap print preview hr anchor pagebreak fullscreen',
      'searchreplace wordcount visualblocks visualchars insertdatetime media nonbreaking',
      'table contextmenu directionality emoticons paste textcolor responsivefilemanager code',
    ],
    toolbar: [
      'undo redo | bold italic underline | alignleft aligncenter alignright alignjustify | bullist numlist | styleselect',
      '| responsivefilemanager | link unlink anchor | image media | fullscreen code',
    ],
    image_advtab: true,
    external_filemanager_path: '/filemanager/',
    filemanager_title: 'File manager',
    external_plugins: {
      filemanager: '/filemanager/plugin.min.js',
    },
    convert_fonts_to_spans: true,
    element_format: 'html',
    fix_list_elements: true,
    force_br_newlines: false,
    force_p_newlines: false,
    forced_root_block: false,
    remove_trailing_brs: true,
    style_formats_merge: true,
    relative_urls: false,
    visualblocks_default_state: true,
    end_container_on_empty_block: true,
    image_class_list: [
      {
        title: 'Responsive',
        value: 'img-fluid',
      },
      {
        title: 'Nessuna',
        value: '',
      },
    ],
  });
}

const resetEditor = () => {
  $('.editor, textarea')
    .parent()
    .find('label')
    .on('click', () => {
      window.tinymce.remove();
      checkWysiwygEditor();
    });
};

$(() => {
  if ($('.custom-scrollbar').length > 0) {
    $('.button-collapse').sideNav();
    // const sideNavScrollbar = document.querySelector('.custom-scrollbar');
    // const ps = new PerfectScrollbar(sideNavScrollbar);
    // Ps.initialize(sideNavScrollbar);
  }

  if ($('.datatable').length > 0) {
    // Datatables
    // https://datatables.net/manual/options
    $('.datatable').DataTable({
      lengthMenu: [
        [10, 20, 50, 100, -1],
        [10, 20, 50, 100, 'Tutte le pagine'],
      ],
      pageLength: 20,
      scrollX: true,
      autoWidth: false,
      language: {
        sEmptyTable: 'Nessun dato presente nella tabella',
        sInfo: 'Vista da _START_ a _END_ di _TOTAL_ elementi',
        sInfoEmpty: 'Vista da 0 a 0 di 0 elementi',
        sInfoFiltered: '(filtrati da _MAX_ elementi totali)',
        sInfoPostFix: '',
        sInfoThousands: '.',
        sLengthMenu: 'Visualizza _MENU_ elementi',
        sLoadingRecords: 'Caricamento...',
        sProcessing: 'Elaborazione...',
        sSearch: 'Cerca:',
        sZeroRecords: 'La ricerca non ha portato alcun risultato.',
        oPaginate: {
          sFirst: 'Inizio',
          sPrevious: 'Precedente',
          sNext: 'Successivo',
          sLast: 'Fine',
        },
        oAria: {
          sSortAscending:
                        ': attiva per ordinare la colonna in ordine crescente',
          sSortDescending:
                        ': attiva per ordinare la colonna in ordine decrescente',
        },
      },
    });
    $('.dataTables_length').addClass('bs-select');
  }

  // DATEPICKER
  $('.datepicker').pickadate({
    format: 'dd/mm/yyyy',
    formatSubmit: 'yyyy-mm-dd',
    hiddenName: true,
    selectYears: 100,
  });

  // Handling modal events
  $('body').on('show.bs.modal', '.modal', (e) => {
    const $self = $(e.target);
    const $btn = $(e.relatedTarget);

    const $href = $btn.attr('href') || null;

    if ($href !== null) {
      $.get($href, (data) => {
        $self.find('.modal-content').html(data);
      });
    }

    setTimeout(() => {
      resetEditor();
      // MDB Select Initialization
      $('.mdb-select').materialSelect();
      $('.datepicker').pickadate({
        format: 'dd/mm/yyyy',
        formatSubmit: 'yyyy-mm-dd',
        hiddenName: true,
        selectYears: 100,
      });
      // $('[autofocus]').parent().find('label').addClass('active');
      $('[autofocus]').focus();
    }, 1500);
  });

  $('body').on('hide.bs.modal', '.modal', (e) => {
    $(e.target).removeData('bs.modal');
  });

  $('body').on('submit', '.modal form', (e) => {
    e.preventDefault();
    const $self = $(e.target);
    const $action = $self.attr('action');
    const $data = $self.serialize();

    $.post($action, $data, (result) => {
      if (result.success) {
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        $self
          .closest('.modal-content')
          .empty()
          .append(result);
      }
    });
  });

  // PaymentTypes Trigger Form
  $('body').on('submit', '#newPaymentFeesTriggerForm', (e) => {
    e.preventDefault();
    const $form = $(e.target);
    const $url = $form.attr('action');
    const $fragment = $form.find('#fragment').val();
    const $amount = $form.find('#amount').val();

    const $uri = `${$url}/${$fragment}/${$amount}`;

    window.location = $uri;
  });

  $('body').on('change', '.observable #amount', (e) => {
    e.preventDefault();

    const $val = $(e.target).val();

    $('.related-payment-fees [id*="amount"]').each((idx, el) => {
      $(el).val($val);
    });
  });

  /* Specific triggers and functions */
  $('body').on('click', '#resetPaymentFees', (e) => {
    e.preventDefault();

    const $fragment = $('#fragment').val();
    const $url = `${$(e.target).attr('href')}/${$fragment}`;
    window.location = $url;
  });

  $(document).on('click', '.printBadgeButton', (e) => {
    e.preventDefault();
    const form = $(e.target).closest('form')[0];
    let actionUrl = form.action;
    actionUrl = `${actionUrl}/true`;
    form.action = actionUrl;
    form.submit();
  });

  // tinymce integration
  $(document).on('focusin', (e) => {
    if ($(e.target).closest('.mce-window, .moxman-window').length) {
      e.stopImmediatePropagation();
    }
  });

  /**
     * Members subscription add form
     */
  $(document).on(
    'change',
    '.member_subscription #user-id, .socialbook_subscription #user_id,.activities #handleSocialYear',
    (e) => {
      e.preventDefault();

      let url = $(e.target)
        .closest('form')
        .attr('action');
      const pattern = /(\d+){1,}/gi;
      const test = url.match(pattern);

      if (!test) {
        url = url.replace(/\/$/gi, '').split('/');
        url.push(e.target.value);
        window.location.href = url.join('/');
      }
      if (!!test && test.length === 1) {
        // Already exist a user id in url
        const lastNumber = /(\d+)$/gi;
        url = url.replace(lastNumber, e.target.value);
      } else if (!!test && test.length === 2) {
        const occurrence = /(\d+)/gi;
        url = url
          .replace(occurrence, '')
          .replace(/\/$/gi, '')
          .split('/');
        url.push(e.target.value);
      }
      if ($.isArray(url)) {
        url = url.join('/');
      }
      window.location.href = url;
    },
  );

  /* Handle Social Year selection on members */
  $(document).on('change', '#handleSelectedSocialYear', (e) => {
    e.preventDefault();
    const {
      target: { value },
    } = e;
    let url = $(e.target)
      .closest('form')
      .attr('action')
      .replace(/\/$/gi, '');
    const selected = /(\d+)/gi;
    const chunks = url.match(selected);

    // if userId is passed...
    if (!!chunks && chunks.length === 1) {
      url = url.replace(/(\d+)$/, 0);
    }

    // if yearId already selected...
    if (!!chunks && chunks.length > 1) {
      url = url.replace(/\/(\d+)$/gi, '');
    }
    url = url.split('/');

    if (!chunks) {
      url.push(0);
    }
    url.push(value);
    url = url.join('/');

    window.location.href = url;
  });

  // Activity edit, update the social_year_id accordingly to the selection
  $(document).on('change', '.activities #handleUpdateSocialYear', (e) => {
    e.preventDefault();

    let url = $(e.target)
      .closest('form')
      .attr('action');

    const test = url.match(/(\d+)/gi);

    if (test) {
      if (test.length === 1) {
        url = url.concat('/', e.target.value);
      } else if (test.length >= 2) {
        /* console.error('numero di parametri errato'); */
      }
    }
    window.location.href = url;
  });

  $(document).on('click', '#socialbook-export__discount-handler', (e) => {
    e.preventDefault();
    e.stopPropagation();

    // const btn = $(e.target);
    const form = $(e.target).closest('form');
    const socialYear = form.find('select').val();
    const url = new URL(
      `${window.location.origin}${form.attr(
        'action',
      )}-discount/${socialYear}`,
    );
    window.location.href = url.toString();
  });

  // Editor instantiation
  checkWysiwygEditor();
  resetEditor();

  // MDB Select Initialization
  $('.mdb-select').materialSelect();

  // dnd fileupload
  $('.file-upload').file_upload();

  const getCookie = (name) => {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
      const cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i += 1) {
        const cookie = jQuery.trim(cookies[i]);
        // Does this cookie string begin with the name we want?
        if (cookie.substring(0, name.length + 1) === `${name}=`) {
          cookieValue = decodeURIComponent(
            cookie.substring(name.length + 1),
          );
          break;
        }
      }
    }
    return cookieValue;
  };

  // ajax setup
  // Adds csrfToken to the AjaxHeader
  $.ajaxSetup({
    beforeSend(xhr, settings) {
      if (
        settings.type === 'POST'
                || settings.type === 'PUT'
                || settings.type === 'DELETE'
      ) {
        if (
          !(
            /^http:.*/.test(settings.url)
                        || /^https:.*/.test(settings.url)
          )
        ) {
          // Only send the token to relative URLs i.e. locally.
          xhr.setRequestHeader('X-CSRF-Token', getCookie('csrfToken'));
        }
      }
    },
  });
});
